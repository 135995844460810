.wpb_gallery{
  overflow: hidden;
  .wpb_flexslider{

    border: none;
    img{
      width: 100%;
      height: auto;
    }
  }

  .wpb_image_grid{
    .wpb_image_grid_ul{
      height: inherit !important;
      li{
        position: relative !important;
        left: inherit !important;
        top: inherit !important;
      }
    }
  }
}

//Image Slider / Caroussel Texte raus
.content-over-slider .slick-slide .content-section{
  display: none;
}

.vc-hoverbox-back{
  h2{
    color: $quaternary !important;
  }
}

@media only screen and (max-width: 600px) {
  .vc-hoverbox.hover .vc-hoverbox-front{
    transform: rotateY(-180deg);
  }
  .vc-hoverbox.hover .vc-hoverbox-back{
    transform: rotateY(0deg);
  }
}

.vc_row-has-fill{
  h2{
    color: $tertiary !important;
  }
  .gelb h2{
    color: $primary !important;
  }
  .gelb h1{
    color: $primary !important;
  }
  p{
    color: $tertiary;
  }
}

.blackback .vc_row-has-fill{
  h2{
    color: $primary !important;
  }
  p{
    color: $quaternary;
  }
}
.yellowback.vc_row-has-fill{
  h2 {
    color: $quaternary !important;
  }
}

.vc_gitem-zone-a .vc_gitem-row-position-top,
.vc_gitem-zone-b .vc_gitem-row-position-top {position: inherit !important;}
.vc-gitem-zone-height-mode-auto:before {padding: inherit !important;}

.vc_gitem-zone-a .vc_gitem-row-position-middle{
  transform: none !important;
  position: inherit !important;
}

.fullpicrow{
  max-height: 450px;
  .vc_column-inner{
    padding: 0 !important;
  }
  .wpb_single_image{
    max-height: 450px;
    margin: 0;
    padding:0;
    overflow: hidden;
    img{
      width: 100% !important;
      height: auto !important;
    }
  }
}

.no_padding_please{
  .vc_column-inner{
    padding: 0px !important;
  }
}


.leistungenteaser{
  >.vc_column_container {
    >.vc_column-inner {
      >.wpb_wrapper{
        background-color: $quaternary;
        box-shadow: 5px 5px 20px -3px rgba(0,0,0,0.75);
          .wpb_single_image{
           margin-bottom: 15px;
          }
          .wpb_text_column{
            padding: 20px;
            margin-bottom: 0px !important;
            h2{
              color: $primary !important;
              border-bottom: solid 1px $primary;
              @media only screen and (max-width: 1024px) {
                word-break: break-word;
              }
            }
          }
          .vc_btn3{
            font-size: 14*$px-base;
            //box-shadow: 5px 5px 20px -3px rgba(0,0,0,0.75);
            //padding-top: 5px;
            //margin-right: 20px;
            //margin-bottom: 20px;

            i{
              font-weight: bold;
            }
          }
      }
    }
  }
}

.home .slidemeinner .wpb_wrapper{
 background-color: #414141;
  .vc_btn3-container a{
    margin: 20px 30px 30px 30px;
  }
}
.slidemeinner{
  padding-bottom: 50px;
  *{
    box-shadow: none !important;
  }
  @media only screen and (max-width: 1024px) {
    h4{
      word-break: break-word;
    }
  }
  .wpb_wrapper{
    background-color: $quaternary;
    box-shadow: 5px 5px 20px -3px rgba(0,0,0,0.75);
    .wpb_single_image{
      margin-bottom: 15px;
    }
    .wpb_text_column{
      padding: 20px;
      margin-bottom: 0px !important;
      h2{
        color: $primary !important;
        border-bottom: solid 1px $primary;
      }
    }
    .vc_btn3{
      font-size: 14*$px-base;
      //box-shadow: 5px 5px 20px -3px rgba(0,0,0,0.75);
      //padding-top: 5px;
      //margin-right: 20px;
      //margin-bottom: 20px;

      i{
        font-weight: bold;
      }
    }

  }
}
.slick-next, .slick-prev {
  position: absolute;
  top: 50%;
  right: 0;
  background-color: transparent;
  width: 60px;
  border: 0px;
  .fa{
    font-size: 50*$px-base;
    color: #ffffff;
  }
}
.home {
  .slick-next, .slick-prev {
    top: 35%;
    .fa{
      color: #000000;
    }
  }
}
.slick-list {
  margin: 0 6rem !important;
}
.slick-prev {
  left: 0;
}
.slick-list {
  width: calc(100% - 12rem);
  margin: auto
}

@media (max-width: 1180px){
  .onmobiletotheleft{
    order: -1;
  }
}

@include media-breakpoint-down(sm) {
  .picsmobiltop {
    flex-direction: column-reverse;
  }
}

.wpb_text_column{
  ul {
    list-style-type: none;
    padding-left: 19px;

    li{
      line-height: 35px;
    }
  }
  ul li:before {
    content: '';
    display: inline-block;
    height:20px;
    width: 20px;
    background-size: cover;
    background-image: url('images/li.svg');
    background-repeat: no-repeat;
    margin-right: 5px;
    margin-left: -21px;
    transform: translate(0px, 4px);
  }
}

.leistungenpagegrid{
  margin: auto!important;
  .vc_col-has-fill > .vc_column-inner{
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;

    .wpb_text_column a{
      color: #000000;
      text-decoration: none;
    }
  }
}

.wpcf7-form{
  label{
    width: 100%;
    input, textarea{
      padding: 5px;
      width: 100%;
      border: none;
      background-color: $primary;
    }
    input[type="checkbox"]{
      border: solid 1px #000000;
      width: inherit;
    }
  }
  .wpcf7-submit{
    background-color: $tertiary;
    padding: 5px;
    width: 100%;
    color: $quaternary;
  }
}

.vc-hoverbox-inner{
  .vc-hoverbox-front-inner{
    h2{
      background-color: $tertiary;
      color: $primary !important;
    }
  }
  .vc_general.vc_btn3{
    font-size: 18*$px-base !important;
    font-weight: bold !important;
  }
}

.nopaddingontop > .wpb_column >.vc_column-inner{
  padding-top: 0px !important;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.nopaddingontop {
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin: 0px !important;

    .wpb_column{
      .wpb_content_element{

        margin-bottom: 0px !important;
      }
    }
  }
}

.footermaps .wpb_wrapper iframe{
  margin-top: -16px;
}

.sfpostgrid{
  min-height: 575px;
}