/*
Theme Name: Flossdorf Template 1.0
Description: Flossdorf Template 1.0
Version: 1.0
Author: Shapefruit AG - Christian Lersch
Author URI: http://shapefruit.de
License: GPL
*/

/* AUTOREN */

/* !!!!!!!!!!!!!!!!!!!!!! RPT VERSION !!!!!!!!!!!!!!!!!!!!!!  */

/* -----------------------------------------
   GLOBALE STYLES
----------------------------------------- */

@font-face {
  font-family: 'Roboto';
  src: url('https://flossdorf.com/wp-content/themes/flossdorf/css/fonts/Roboto-400-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('https://flossdorf.com/wp-content/themes/flossdorf/css/fonts/Roboto-Bold-700.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

#sfCookie .modal-content{
  display: block;
}

html body{
  font-size: 16*$px-base;
  font-family: $font-family-base;

}

h1{
  color: $primary;
  font-size: 50*$px-base;
  @include media-breakpoint-down(sm) {
    font-size: 30*$px-base;
  }
}

h2{
  color: $primary;
}

.container, .container-fluid{
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.row .container{
  margin: auto;
}

.vc-hoverbox-inner {
  min-height:450px!important;
}

.vc_btn3.vc_btn3-size-lg .vc_btn3-icon{
  font-size: 30*$px-base !important;
}

//CHANGE ORANGE COLOR TO FLOSSDORF YELLOW
.vc_btn3.vc_btn3-color-orange, .vc_btn3.vc_btn3-color-orange.vc_btn3-style-flat{
  background-color: $primary !important;
}

@include media-breakpoint-down(sm) {
  .home .slidemeinner .wpb_wrapper .vc_btn3-container a{
    margin: 0px;
    padding: 0px;
    border: 0px;
    word-break: break-all;
  }


  .centeronmobile{
    *{
      text-align: center;
    }
    .wpb_single_image.vc_align_left{
      text-align: center !important;
    }
    .wpb_single_image .vc_figure{
      width: 100% !important;
      img{
        margin: auto;
      }
    }
  }
}


.wp-video, video.wp-video-shortcode, .mejs-container, .mejs-overlay.load {
  width: 100% !important;
  height: 100% !important;
}
.mejs-container {
  padding-top: 56.25%;
}
.wp-video, video.wp-video-shortcode {
  max-width: 100% !important;
}
video.wp-video-shortcode {
  position: relative;
}
.mejs-mediaelement {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mejs-controls {
  display: none;
}
.mejs-overlay-play {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto !important;
  height: auto !important;
}
