
.startseite-postteaser {
  .vc_gitem-row-position-middle{
    max-width: 1030px;
    margin:auto;
    padding: 32px;
    background-color: $quaternary;
  }

  .teaserheadline *{
    color: $primary;
    font-size: 40*$px-base;
  }

  .vc_gitem-post-data-source-post_title div{
    font-weight: bold;
  }

  .vc_gitem-post-data-source-post_date div{
    font-weight: normal;
  }

  .vc_gitem-post-data-source-post_excerpt *{
    color: $tertiary;
    text-align: center;
  }

  .wpb_content_element{
    margin-bottom: 0px !important;
  }

  .vc_gitem-col-align- {
    padding: 0px !important;
  }

  .wpb_single_image {
    .vc_figure {
      width: 100%;
    }

    img {
      width: 100% !important;
      height: auto;
    }
  }
}

//Test für Video Header
.page-id-6184{
  .headerbar{
    background-color: transparent!important;
  }
  .breadcrumbs{
    display: none;
  }
  .videobar{
    position: relative;
    top: -142px;
    z-index: -100;
  }
}