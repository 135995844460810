$primary: #f1cb00;
$secondary: #000000;
$tertiary: #1a1a1a;
$quaternary: #ffffff;

$font-family-base: 'Roboto', sans-serif;
$font-size-base: 1rem;
$px-base: $font-size-base/16;

$grid-columns: 12;
$grid-gutter-width: 5px;

$border-radius:                     20px;
$card-border-radius:                0px;
$card-spacer-y:                     5px;
$card-spacer-x:                     5px;
$card-border-width:                 0px;
$card-bg:                           $primary;

$input-btn-padding-y:               0.175rem;

//$font-size-base: 1rem;
//$px-base: $font-size-base/17;
//$line-height-base: 1.411764706;

//$font-primary: #002438;
//$font-secondary: $brand-red;

//$primary: $brand-red;
//$secondary: $brand-primary;

//$body-color: $brand-primary;

//$line-height-base: 1.7;

//$headings-font-family: 'Quicksand', sans-serif;
//$headings-font-weight: 'normal';
//$headings-color: $brand-primary;

//$std-margin: 40px;

//$breadcrumb-bg: transparent;
//$breadcrumb-padding-x: 0;
//$breadcrumb-padding-y: 0.5rem;
//$breadcrumb-margin-bottom: 0;
//$breadcrumb-divider: '•';

//$input-bg: $brand-gray;
//$input-border-radius: 0;
//$input-border-width: 0;
//$btn-border-radius: 0;
//$tooltip-max-width: 20rem;