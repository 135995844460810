
.flyoutbuttons{
  position: fixed;
  padding-top: 60px;
  bottom: 50%;
  right: 0px;

  margin: auto;
  z-index: 1000;

  @include media-breakpoint-down(xs) {
    display: none;
  }


  .erreichbarkeit{
    display: flex;
    position: relative;
    flex-direction: column;

    a{
      color: $tertiary;
      text-decoration: none;
    }
    .openerreichbarkeit, .openerreichbarkeit2, .openerreichbarkeit3{
      display: flex;
      color: $tertiary;
      padding: 10px;
      background-color: $primary;
      height: 92px;
      z-index: 1010;
      line-height: 1px;
      align-items: center;
    }
    .erreichbarkeitfoldout, .erreichbarkeitfoldout2, .erreichbarkeitfoldout3{
      padding: 10px;
      position: fixed;
      right: 70px;
      width: 200px;
      background-color: $primary;
      font-weight: bold;
      display: none;
      transition: right 1s;
      &.show{
        right: 80px;
      }
      &.hover{
        display: block;
      }
    }
    .erreichbarkeitfoldout2{
      margin-top: 100px;
    }
    .erreichbarkeitfoldout3{
      margin-top: 208px;
    }
  }
  .faq{
    a{
      display: inline-block;
      color: $tertiary;
      padding: 10px;
      background-color: $primary;
      height: 92px;
      z-index: 1010;
      line-height: 1px;
      align-items: center;
    }
  }

}

