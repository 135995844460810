.footer{
  .adresscardrow{
    //margin-bottom: -21px;
    .wpb_raw_code{
      margin: 0px !important;
      .wpb_wrapper{
        line-height: 1px;
      }
    }
    .adressrow{
      > .vc_column-inner{
        padding: 0px 40px !important;
        h2{
          padding-top: 40px;
        }
      }
    }
    .footercontactbar{
      background-color: $primary;
      padding: 30px 10px;

      .container{
        margin: auto;
      }
      button{
        color: $primary;
      }
      .wpb_content_element{
        margin-bottom: 0px;
      }
    }
  }
  .footernavigationblack{

    justify-content: center;
    padding: 30px;
    background-color: #414141;

    .footernav{
      ul{
        list-style-type: none;
        display: flex;
        justify-content: center;
        padding-left: 0px;
        @include media-breakpoint-down(sm){
          flex-direction: column;
        }
        li{
          text-align: center;
          a{
            padding: 10px;
          }
        }
      }
    }
    .socialmedia{
      display: flex;
      justify-content: center;

      a{
        color: $quaternary;
        font-size: 22*$px-base;
        padding: 10px;
      }
    }
  }
}

.home .footercontactbar{
  display: none !important;
}