.headerbar{
  background-color: $primary;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
  @include media-breakpoint-down(sm) {
    //flex-direction: column;
  }

  .logo{
    padding-left: 30px;
    .logosloganbox{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .logobox{
        img{
          width:240px;
          @include media-breakpoint-down(sm) {
            width:180px;
            height: inherit;
          }
        }
        @include media-breakpoint-down(sm) {
          //text-align: center;
          //flex: 0 1 auto;
        }
      }
      .sloganbox_mobil{
        display: none;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        .sloganbox_desktop{
          display: none;
        }
        .sloganbox_mobil{
          display: block;
        }
      }
    }
  }

  .navicol{
    width: 100% !important;
    @include media-breakpoint-down(lg) {
      text-align: center;
      flex: 0 1 auto;
      .ubermenu-responsive-toggle{
        text-align: center;
        font-size: 16*$px-base;
      }
      nav{
        position: absolute;
        background-color: $primary;
        min-width: 375px;
        right: 0;
      }
    }
    @include media-breakpoint-down(xs) {
      nav {
        min-width: 320px;
      }
    }
    @media (min-width: 1024px) and (max-width: 1200px) {
      //margin-top: -82px;
    }
  }

  //.mobilmenubutton{
  //  display: none;
  //  @include media-breakpoint-down(lg) {
  //    display: block;
  //  }
  //}
  //
  //.hauptnavigation{
  //  @include media-breakpoint-down(lg) {
  //    display: none;
  //  }
  //  ul{
  //    list-style-type: none;
  //    display: flex;
  //    justify-content: center;
  //    margin: 0;
  //    padding: 0;
  //
  //    .sub-menu{
  //      display: none;
  //      position: absolute;
  //      z-index: 1200;
  //      background-color: $primary;
  //      top: 80px;
  //      li{
  //        padding: 10px;
  //        text-align: center;
  //        width: 200px;
  //        border-top: solid 1px $tertiary;
  //        a{
  //          font-size: 16*$px-base;
  //          padding: 0;
  //        }
  //      }
  //    }
  //    .navarrowmobil{
  //      display: inline-block;
  //      position: absolute;
  //      top: 0px;
  //      right: 0px;
  //      font-size: 33*$px-base;
  //      line-height: 20px;
  //      .fa{
  //        font-weight: bold;
  //      }
  //    }
  //
  //    li.hover{
  //      .sub-menu {
  //        display: block;
  //      }
  //    }
  //
  //    li{
  //      position: relative;
  //      a{
  //        padding: 20px;
  //        padding-right: 30px;
  //        color: $tertiary;
  //        font-size: 22*$px-base;
  //      }
  //    }
  //  }
  //}
  .ubermenu{
    .ubermenu-target-title.ubermenu-target-text{
      text-align: center;
      text-transform: uppercase;
      @include media-breakpoint-down(sm){
        font-size: 13px;
      }
    }
    ul.ubermenu-submenu-type-mega{
      background-color: $primary !important;
      border: none !important;

      a:hover{
        color: #ffffff !important;
      }
    }
    i.ubermenu-sub-indicator{
      display: none;
    }
  }

  .search{
    position: relative;
    @include media-breakpoint-down(sm){
      display: none;
    }
    #s{
      width: 100%;
      background-color: transparent !important;
      border: none !important;
      border-bottom: solid 1px $tertiary !important;
      color: $tertiary !important;
    }
    #searchsubmit{
      position: absolute;
      top: -5px;
      right: 0px;
      width: 38px;
      height: 38px;
      border-bottom: none;
      border: none;
      background-color: transparent !important;
      color: $tertiary !important;
    }
  }
}

.ubermenu-loc-hauptnavigation {
  .ubermenu-active{
    background-color: #f7e066 !important;
  }
}

.ubermenu .ubermenu-item-layout-image_above>.ubermenu-image{
  margin: auto;
}

@include media-breakpoint-down(sm) {
  .ubermenu-loc-hauptnavigation {
    .ubermenu-target-with-image {
      img {
        display: none;
      }
    }
    ul li{
      border-top: solid 1px #000000 !important;
      //border-bottom: solid 1px #000000 !important;
      a{
        text-align: left !important;
        span{
          text-align: left !important;
        }
      }
    }
    //ul li:first-of-type{
    //  border-top: solid 2px #000000 !important;
    //}
    //ul li:last-of-type{
    //  border-bottom: solid 2px #000000 !important;
    //}

  }
}

//.hauptnavigationmobil{
//  display: none;
//  background-color: $primary;
//
//  @include media-breakpoint-down(lg) {
//    ul {
//      display: block !important;
//    }
//  }
//  ul{
//    list-style-type: none;
//    padding: 0px;
//    li{
//      text-align: center;
//      padding: 15px;
//      border-top: 1px solid $tertiary;
//      a{
//        color: $tertiary;
//        font-weight: bold;
//        font-size: 22*$px-base;
//      }
//      .sub-menu{
//        li{
//          padding: 5px;
//          border:none;
//          a{
//            font-size: 16*$px-base;
//            font-weight: normal;
//          }
//        }
//      }
//    }
//  }
//
//  &.show{
//    display:block;
//  }
//}

