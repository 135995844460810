.breadcrumbs{
  padding: 20px 0px;
  padding-left: 30px;
  a{
    color: $tertiary;

    &.current-item{
      color: $primary;
      text-transform: uppercase;
    }
  }
  .seperator{
    padding: 0px 10px;
    i{
      font-size: 20*$px-base;
    }
  }
}