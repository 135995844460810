.postthumbnail{
  img{
    width: 100%;
    height: auto;
  }
}

.single-post .postthumbnail{
  height: 600px;
}

.postheadline{
  padding:30px 0px;
}

.spacer{
  padding: 30px 0px;
}

.postgridview{
  .wp-post-image{
    width: 100% !important;
    height: auto;
  }
  .card-text{
    .text-muted{
      color: $tertiary !important;
    }
  }
  .card-title a{
    color: $quaternary;
    text-transform: uppercase;
  }
  .card-footer{
   background-color: transparent !important;
    .btn{
      background-color: $quaternary;
      color: $primary;
      border-radius: 0;
      text-transform: none;
    }
  }
}

.vc_grid-container{
  .vc_gitem-col-align-{
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .vc_gitem-row-position-middle{
    padding: 0px 30px;
  }

  .vc_gitem-post-data-source-post_title{
    font-weight: bold;
    text-transform: uppercase;
  }
}