.nf-form-cont{
 &.zufriedenheitsabfrage{
   .nf-form-content{
     margin: 0px;
   }
   hr{
     margin: 35px 0px;
   }
   ul{
     li::before{
       width: inherit;
       height: inherit;
       background: none;
     }
   }
   .list-radio-wrap{
     ul{
       display: flex;
       li{
         margin-right: 25px !important;
       }
     }
   }
 }
}